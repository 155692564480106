'use strict';
function setDealsFilters(filters) {
    localStorage.setItem('deal_filters', JSON.stringify(filters));

}

function getDealsFilters() {
    const filters = localStorage.getItem('deal_filters');
    if (filters) {
        return JSON.parse(filters);
    } else {
        return null;
    }
}

export { setDealsFilters, getDealsFilters }