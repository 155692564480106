<script setup>
import { onMounted, ref } from 'vue';
import { useDealStore } from '../stores/deals';
import { useDealerStore } from '../stores/dealer';
import { useAuthStore } from '../stores/auth';
import { getDealsWithFilters } from '../services/deal_apis'
import { useSystemStore } from '../stores/system';
import { setDealsFilters, getDealsFilters } from '../services/local_storage';

const authStore = useAuthStore();
const dealStore = useDealStore();
const dealerStore = useDealerStore();
const systemStore = useSystemStore();
const deals = ref([]);
const meta = ref({
    total_pages: 0,
    next_page: 0,
    prev_page: null,
    page: null
});
const filters = ref({
    limit: 20
});
const convertToFloat = (str) => {
    let num = parseFloat(str);
    if (isNaN(num)) {
        return 0;
    }
    // Round to 2 decimal places
    num = Math.round(num * 100) / 100;

    // Add commas to the number
    return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

}

onMounted(() => {
    const localStorageFilters = getDealsFilters();
    if (localStorageFilters != null) {
        dealStore.filters.limit = localStorageFilters.limit;
    }
    loadDeals();
})

async function loadDeals(page = 1) {
    if (page === null) {
        return;
    }
    systemStore.enableLoadingStatus();
    const deals_data = await getDealsWithFilters(page);
    if (deals_data?.status == 200) {
        deals.value = deals_data.data.data.deals;
        meta.value = deals_data.data.meta;
        systemStore.disableLoadingStatus();
    }

}

function updateLimit(limit) {
    filters.value.limit = limit;
    dealStore.filters.limit = limit;
    setDealsFilters(filters.value);
    loadDeals();
}
</script>

<template>
    <h3 class="text-3xl font-bold">Deals</h3>

    <div v-if="!dealerStore.dealerData.dealer_settings.plan.features.deals"
        class="grid border border-gr-green rounded-lg p-4 text-center my-8 bg-white gap-2 shadow">
        <div class="text-xl font-semibold">Deals Not Available</div>
        <div>This feature is not enabled for your dealership. Please contact sales.</div>
        <div class="my-4">
            <a href="https://garageregister.ca/sales/" target="_blank"
                class="bg-gr-green px-4 py-2 rounded-md text-white text-base">Contact Sales</a>
        </div>
    </div>
    <div v-if="dealerStore.dealerData.dealer_settings.plan.features.deals" class="hidden grid grid-cols-8 gap-3 my-4">
        <div class="border rounded-md bg-gr-grid-top border-slate-200 px-4 py-2 text-center space-y-1">
            <div><span class="text-3xl font-bold text-gray-700">14</span></div>
            <div class="text-sm font-medium">Pending Deals</div>
        </div>
        <div class="border rounded-md bg-gr-grid-top border-slate-200 px-4 py-2 text-center space-y-1">
            <div><span class="text-3xl font-bold text-gray-700">14</span></div>
            <div class="text-sm font-medium">Sold Deals</div>
        </div>
        <div class="border rounded-md bg-gr-grid-top border-slate-200 px-4 py-2 text-center space-y-1">
            <div><span class="text-3xl font-bold text-gray-700">14</span></div>
            <div class="text-sm font-medium">Draft Deals</div>
        </div>
        <div class="border rounded-md bg-gr-grid-top border-slate-200 px-4 py-2 text-center space-y-1">
            <div><span class="text-3xl font-bold text-gray-700">14</span></div>
            <div class="text-sm font-medium">Delivered Deals</div>
        </div>
        <div class="border rounded-md bg-gr-grid-top border-slate-200 px-4 py-2 text-center space-y-1">
            <div><span class="text-3xl font-bold text-gray-700">$51,250</span></div>
            <div class="text-sm font-medium">Revenue in Pending Deals</div>
        </div>
        <div class="border rounded-md bg-gr-grid-top border-slate-200 px-4 py-2 text-center space-y-1">
            <div><span class="text-3xl font-bold text-gray-700">$33,500</span></div>
            <div class="text-sm font-medium">Revenue in Sold Deals</div>
        </div>
        <div class="border rounded-md bg-gr-grid-top border-slate-200 px-4 py-2 text-center space-y-1">
            <div><span class="text-3xl font-bold text-gray-700">$71,180</span></div>
            <div class="text-sm font-medium">Revenue in Delivered Deals</div>
        </div>
        <div class="border rounded-md bg-gr-green border-gr-green text-white px-4 py-2 text-center space-y-1">
            <div><span class="text-3xl font-bold text-white">$11,250</span></div>
            <div class="text-sm font-medium">Profit in Delivered Deals</div>
        </div>
    </div>
    <div class="my-2 flex flex-col md:flex-row justify-between justify-items-stretch sm:items-stretch gap-2 items-center"  v-if="dealerStore.dealerData.dealer_settings.plan.features.deals">
        <div class="hidden md:flex flex-col md:flex-row gap-2">
            <div class="flex flex-row items-stretch">
                <select class="rounded-md px-4 border"
                    :class="{ 'outline outline-2 outline-primaryColor': dealStore.filters.deal_status !== '' }"
                    v-model="dealStore.filters.deal_status" v-on:change="loadDeals()">
                    <option :value="''" selected>All Deals</option>
                    <option :value="'open'">Open</option>
                    <option :value="'sold'">Sold</option>
                    <option :value="'delivered'">Delivered</option>
                    <option :value="'lost'">Lost</option>
                </select>
            </div>
            <div class="flex justify-items-stretch">
            </div>
        </div>
        <div v-if="dealerStore.dealerData.dealer_settings.plan.features.deals"
            class="my-2 flex flex-row justify-end items-stretch">
            <div class="flex px-2 items-stretch">
                <input type="text" class="rounded px-2 w-72 text-xs" v-model="dealStore.filters.keyword" v-on:keypress.enter="loadDeals()"
                    placeholder="Search by Deal #, Customer Name, Stock # ...">
            </div>
            <router-link to="/deals/create" class="bg-gr-green px-4 py-2 rounded-md text-white text-sm">Create A
                Deal</router-link>
        </div>
    </div>
    <div v-if="dealerStore.dealerData.dealer_settings.plan.features.deals" class="border rounded-t-md flex flex-col ">
        <div
            class=" grid-cols-12 w-full bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4 hidden md:grid">
            <div>Deal #</div>
            <div>Deal Date</div>
            <div class="col-span-2">Customer Name</div>
            <div class="col-span-2">Deal Status</div>
            <div class="col-span-2">Vehicle</div>
            <div class="col-span-2">Stock #</div>
            <div>Total Sale</div>
            <div></div>
        </div>
        <div v-for="deal in deals" class="md:hidden">
            <div
                class="grid grid-cols-10 gap-2 items-center w-full px-4 py-3 border-t text-sm bg-white border-l-gr-green border-l-2 cursor-pointer hover:bg-gr-grid-top">
                <div class="col-span-7 font-bold">{{ deal.customer_firstname }} {{ deal.customer_lastname }}</div>
                <div class="col-span-3 flex justify-items-stretch justify-stretch">
                    <div v-if="deal.deal_status === 'open'"
                        class="bg-[#2196F3] text-white py-1 px-3 rounded w-full text-center text-xs">Open</div>
                    <div v-if="deal.deal_status === 'sold'"
                        class="bg-[#4CAF50] text-white py-1 px-3 rounded w-full text-center text-xs">Sold</div>
                    <div v-if="deal.deal_status === 'delivered'"
                        class="bg-[#015e54] text-white py-1 px-3 rounded w-full text-center text-xs">Delivered</div>
                    <div v-if="deal.deal_status === 'lost'"
                        class="bg-[#BDBDBD] text-black py-1 px-3 rounded w-full text-center text-xs">Lost</div>
                </div>
                <div class="col-span-7 font-semibold">{{ deal.vehicle_info }}</div>
                <div class="col-span-3 flex justify-end">Stk #: {{ deal.vehicle_stock_no }}</div>

                <div class="col-span-4">Deal #: {{ deal.id }}</div>
                <div class="col-span-3 font-semibold">${{ convertToFloat(deal.total_price) }}</div>
                <div class="col-span-3 items-center flex justify-end">
                    <router-link :to="'/deals/' + deal.deal_id + '/edit'"
                        class="border px-2 py-1.5 w-full text-center rounded-md border-gray-300 hover:bg-gr-green hover:text-white hover:border-gr-green">View
                        Deal</router-link>
                </div>
            </div>
        </div>
        <div v-for="deal in deals" class="hidden md:block">
            <div
                class="grid grid-cols-12 items-center w-full px-4 py-3 border-t text-sm bg-white border-l-gr-green border-l-2 cursor-pointer hover:bg-gr-grid-top">
                <div>{{ deal.deal_id }}</div>
                <div>{{ deal.deal_date }}</div>
                <div class="col-span-2">{{ deal.customer_firstname }} {{ deal.customer_lastname }}</div>
                <div class="col-span-2 flex">
                    <div v-if="deal.deal_status === 'open'"
                        class="bg-[#2196F3] text-white py-1 px-3 rounded w-24 text-center">Open</div>
                    <div v-if="deal.deal_status === 'sold'"
                        class="bg-[#4CAF50] text-white py-1 px-3 rounded w-24 text-center">Sold</div>
                    <div v-if="deal.deal_status === 'delivered'"
                        class="bg-[#015e54] text-white py-1 px-3 rounded w-24 text-center">Delivered</div>
                    <div v-if="deal.deal_status === 'lost'"
                        class="bg-[#BDBDBD] text-black py-1 px-3 rounded w-24 text-center">Lost</div>
                </div>
                <div class="col-span-2">{{ deal.vehicle_info }}</div>
                <div class="col-span-2">{{ deal.vehicle_stock_no }}</div>
                <div>${{ deal.total_price }}</div>
                <div><router-link :to="'/deals/' + deal.deal_id + '/edit'"
                        class="border px-2 py-1.5 rounded-md border-gray-300 hover:bg-gr-green hover:text-white hover:border-gr-green">View
                        Deal</router-link></div>
            </div>
        </div>

    </div>
    <div class="flex justify-between">
        <div class="my-2 pl-2 flex items-center gap-2">
            <div class="text-sm">Deals to display</div>
            <select v-model="dealStore.filters.limit" v-on:change="updateLimit(dealStore.filters.limit)">
                <option :value="20">20</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
            </select>
        </div>
        <div class="my-2">
            <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                <a href="#"
                    class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    :class="{ 'cursor-not-allowed': meta.prev_page === null }" v-on:click="loadDeals(meta.prev_page)">
                    <span class="sr-only">Previous</span>
                    <svg class="size-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                        <path fill-rule="evenodd"
                            d="M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z"
                            clip-rule="evenodd" />
                    </svg>
                </a>
                <div v-for="page in meta.total_pages">
                    <a href="#"
                        class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        :class="{ 'bg-gr-green !text-white hover:!bg-gr-green': page === meta.page }"
                        v-on:click="loadDeals(page)">{{ page }}</a>
                </div>

                <a href="#"
                    class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    :class="{ 'cursor-not-allowed': meta.next_page === null }" v-on:click="loadDeals(meta.next_page)">
                    <span class="sr-only">Next</span>
                    <svg class="size-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                        <path fill-rule="evenodd"
                            d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z"
                            clip-rule="evenodd" />
                    </svg>
                </a>
            </nav>
        </div>
    </div>
</template>