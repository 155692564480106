import { defineStore } from "pinia";
import { ref } from "vue";
import axios from "axios";
import { useAuthStore } from "./auth";
import { getVehiclesApi, getVehiclesWithFiltersApi, searchVehicles } from "../services/vehicle_apis";

export const useVehiclesStore = defineStore('vehiclesStore', () => {

    const vehicles = ref([]);
    const activeVehicles = ref([]);
    const vehiclesCount = ref([]);
    const filteredVehicles = ref([]);
    const authStore = useAuthStore();
    const vehicleModal = ref([])
    const vehicleModalStatus = ref(false)
    const filters = ref({
        keyword: '',
        missing_images: false,
        missing_price: false,
        has_issues: false,
        status: 'all',
        date: ''
    });
    const vehicleLoadingStatus = ref(false);

    const getVehicles = () => {
        getVehiclesApi();
    }

    const filterVehicles = () => {
        return authToken.value;
    }


    const setVehicles = (vehiclesList) => {
        vehicles.value = vehiclesList;
    }

    const resetVehicles = () => {
        vehicles.value = []
    }

    const setVehicleModal = (vehicleId) => {
        vehicleModalStatus.value = true
        
    }

    const resetVehicleModal = () => {
        vehicleModalStatus.value = false
    }

    const toggleMissingImagesFilter = () => {
        if (filters.value.missing_images === false) {
            filters.value.missing_images = true;
        } else {
            filters.value.missing_images = false;
        }
        getVehiclesWithFiltersApi()
    }

    const toggleMissingPriceFilter = () => {
        if (filters.value.missing_price === false) {
            filters.value.missing_price = true;
        } else {
            filters.value.missing_price = false;
        }
        getVehiclesWithFiltersApi()
    }

    const toggleInventoryAgeFilter = (date) => {
        filters.value.date = date;
        getVehiclesWithFiltersApi();
    }

    const triggerFilteredSearch = () => {
        getVehiclesWithFiltersApi()
    }
    const triggerKeywordSearch = () => {
        searchVehicles();
    }
    return {
        vehicles,
        getVehicles,
        setVehicles,
        resetVehicles,
        setVehicleModal,
        resetVehicleModal,
        vehicleModal,
        vehicleModalStatus,
        filters,
        toggleMissingImagesFilter,
        toggleMissingPriceFilter,
        triggerFilteredSearch,
        toggleInventoryAgeFilter,
        triggerKeywordSearch,
        vehicleLoadingStatus
    }
})